.modalstyle .modalheader {
    border-bottom: none;
}

.modalstyle .modalbody {
    border-bottom: none;
}

.modalstyle [class="modal-content"] {
    background: var(--cardbg);
    border-radius: 15px;

    /* box-shadow: var(--themecolor) -1px 0px 14px; */
    /* border: 1px solid var(--themecolor); */
    min-width: 320px;
    max-width: 420px;
    margin: auto;
   
    width: 100%;
    /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter{
    padding: 20px;
    border-top: 0px;
}


.modalstyle .modalheader .modaltitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1;
    color: var(--whitetext);

}
.modalstyle .modalheader{
    display: flex;
    justify-content: space-between;
    padding: 25px;
}
.modalstyle .modalheader .closebtn{
    background: none;
    padding: 0px;
    border: none;
    color: var(--themecolor);
    cursor: pointer;
}
.modalstyle .modalbody {
    /* display: flex;
    gap: 20px;
    justify-content: space-evenly; */
    padding:  25px;

}
.modalstyle .modalbody .label{
    font-size: 12px;
    color: var(--whitetext);
}


.modalstyle .modalfooter .footerdesc{
    color: var(--whitetext);
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
}
.modalstyle .modalbody .innerhead{
    color: var(--themecolor);
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 24px;
    text-transform: uppercase;
}

.modalstyle .modalbody .innerheadwhite
{
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
}

.innerheadwhitesm
{
    color: var(--whitetext);
    font-size: 13px;
    line-height: 1.5;
}
.innerheadthemesm
{
    color: var(--themecolor);
    font-size: 13px;
    line-height: 1.5;
}

.modalstyle
{
    padding-right: 8px !important;
}

.modalstyle .modalbody ul{
    list-style:none;
    padding-left: 0px;
}

@media screen and (max-width:400px) {
    .modalstyle .modalbody .innerheadwhite
{
    font-size: 14px;
}


    .modalstyle [class="modal-content"] {
        min-width: 280px;
        max-width: 280px;
    }
   
}

@media screen and (max-width:575px)
{
    .buttonstyle_ul

{
    padding-left: 9px !important;
    padding-right: 9px !important;
}
}




.buttonstyle_ul
{
    background-color: var(--bgcolor);
    color:#fff;
    border-radius: 0px;
    padding: 8px 13px;
    font-size: 12px;
    font-weight: 600;
}



.buttonstyle_ul:hover,.buttonstyle_ul:focus
{
    background: rgb(90 180 55 / 26%);
    color:#fff;
}

.form-control:focus
{
    box-shadow: 0 0 0 0.25rem rgb(72 75 81 / 12%) !important;
}















 .inputsec input, .inputsec input:hover, .inputsec input:focus {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background: var(--bgcolor);
    border: none;
    color: #fff;
    font-size: 12px;
}

.inputsec .inputspan {
    background: var(--bgcolor);
    border: none;
    color: #fff;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 2px solid #434343;
    font-size: 12px;
    height: 100%;
    padding: 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

}

.inputsec .inputspan:hover {
    background: var(--themecolor);
    color: var(--bgcolor);

}

.inputsec .balsec {
    text-align: end;
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
    color: var(--whitetext);

}
.themelink
{
    color:var(--themecolor);
    text-decoration: none;
    font-size: 13px;
}

.themelink:hover
{
    color:var(--whitetext);
}
.textwhitedesc
{
    color:var(--whitetext);
    font-size: 14px;
}
.textgreydesc
{
    color:var(--lighttext);
    font-size: 14px;
}

.modalstyle .btnstyle {
    background: var(--btnliner);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 10px 20px;
    border-radius: 11px;
    width: 100%;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.modalstyle .btnstyle:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #000 !important;
}

.cancelbtn {
    background: var(--redtext);
    border: 1px solid var(--redtext);
    border-radius: 11px;
    box-shadow: 0 4px 4px 0 #00000040;
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
    padding-top: 5px;
    transition: none 0s ease 0s;
    white-space: nowrap;
    width: 100%;
}
.cancelbtn:hover
{
    background: var(--gradtheme) !important;
    border: 1px solid var(--gradtheme) !important;
    color: rgb(255, 255, 255) !important;
    transition: none 0s ease 0s !important;
}
.cancelbtn:disabled
{
    background: var(--redtext) !important;
    color: var(--whitetext);
    border: 1px solid var(--redtext);
    opacity: 0.5;
}

.connectwalletbtn {
    background: var(--btnliner);
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    width: 100%;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.connectwalletbtn:disabled
{
    background: var(--themecolor);
   opacity: 0.5;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
}
.connectwalletbtn:hover
{
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color:#000;
    transition: none 0s ease 0s !important;
}




.innerheadwhitetitle
{
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
    background-color: var(--bgcolor);
    padding: 8px 10px;
    border-radius: 2px;
}

.currencybtn{
    background: var(--bgcolor);
    padding: 10px;
    color: var(--whitetext);
    font-size: 14px;
    border: none;
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 20px;
    padding-right: 20px;
}
.currencybtn:hover{
    background: var(--themehover);
    color:var(--whitetext);
}
.coinimg{
    width: 20px;
    height: 20px;
    object-fit: contain;
}
.coinimg1
{
    position: relative;
    z-index: 1;
}
.coinimg2
{
    margin-left: -13px;
}



.modal_ddrop .dropdownstyle button {
    background: var(--bgcolor);
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

}

.modal_ddrop .dropdownstyle button:active {
    background: var(--bgcolor);
    border: none;
}

.modal_ddrop .dropdownstyle .dropdownmenustyle {
    background: var(--bgcolor);
    border: none;
    width: 100%;
    color: var(--whitetext);
    font-size: 14px;

}

.modal_ddrop .dropdownstyle .dropdownmenustyle a {
    color: var(--whitetext);
    font-size: 14px;
}

.modal_ddrop .dropdownstyle .dropdownmenustyle a:hover {
    color: var(--whitetext);
    background: var(--themecolor);
}
.dropdownmenuscrl
{
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0px 0px 3px 1px #c7bbbb45;
}

.innerheadwhitesmbrkval
{
    word-break: break-all;
}


.themenote
{
    color:var(--themecolor);
    font-size: 14px;
}