.modalstyle .modalheader {
    border-bottom: none;
}

.modalstyle .modalbody {
    border-bottom: none;
}

.modalstyle [class="modal-content"] {
    background: var(--cardbg);
    /* box-shadow: var(--themecolor) -1px 0px 14px; */
    /* border: 1px solid var(--themecolor); */
    min-width: 370px;
    max-width: 370px;
    margin: auto;
    border-radius: 15px;
    width: 100%;
    /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter{
    padding: 20px;
    border-top: 0px;
}
.modalstyle .btnstyle {
    background: var(--btnliner);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 10px 20px;
    border-radius: 11px;
    width: 100%;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.modalstyle .btnstyle:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #000 !important;
}
.modalstyle .modalheader .modaltitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1;
    color: var(--whitetext);

}
.modalstyle .modalheader{
    display: flex;
    justify-content: space-between;
    padding: 25px;
}
.modalstyle .modalheader .closebtn{
    background: none;
    padding: 0px;
    border: none;
    color: var(--themecolor);
    cursor: pointer;
}
.modalstyle .modalbody {
    /* display: flex; */
    /* gap: 20px; */
    /* justify-content: space-evenly; */
    padding: 40px 25px;

}



@media screen and (max-width:400px) {
    .modalstyle .modalbody .innerheadwhite
{
    font-size: 14px;
}

    .modalstyle [class="modal-content"] {
        min-width: 280px;
        max-width: 280px;
    }
   
}

.clrsec{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.translist{
    color: var(--whitetext);
    font-size: 14px;
    margin-bottom: 5px;
    text-decoration: none;
    background-color: transparent;
    border: none;
}
.translist svg{
    margin-left: 5px;

}
.translist:hover{
    color: var(--themecolor);

}
.translist:hover svg{
    fill: var(--themecolor);
    
}
.clrbtn{
    background: var(--themecolor);
    padding: 5px 10px;
    font-size: 14px;
    color: var(--whitetext);
}
.clrbtn:hover{
    background: var(--btnhover);
}
