.modalstyle .modalheader {
    border-bottom: none;
}

.modalstyle .modalbody {
    border-bottom: none;
}

.modalstyle [class="modal-content"] {
    background: var(--cardbg);
    border-radius: 15px;

    /* box-shadow: var(--themecolor) -1px 0px 14px; */
    /* border: 1px solid var(--themecolor); */
    min-width: 370px;
    max-width: 370px;
    margin: auto;
    
    width: 100%;
    /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter{
    padding: 20px;
    border-top: 0px;
}
.modalstyle .btnstyle {
    background: var(--btnliner);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 10px 20px;
    border-radius: 11px;
    width: 100%;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.modalstyle .btnstyle:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #000 !important;
}
.modalstyle .modalheader .modaltitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1;
    color: var(--whitetext);

}
.modalstyle .modalheader{
    display: flex;
    justify-content: space-between;
    padding: 25px;
}
.modalstyle .modalheader .closebtn{
    background: none;
    padding: 0px;
    border: none;
    color: var(--themecolor);
    cursor: pointer;
}
.modalstyle .modalbody {
    /* display: flex;
    gap: 20px;
    justify-content: space-evenly; */
    padding:  25px;

}

.modalstyle
{
    padding-right: 8px !important;
}
.modalstyle .balsec {
    margin-bottom: 10px;
}
.modalstyle .balsec .balvalue {
    font-size: 20px;
    color: var(--themecolor);
    font-weight: 600;
    margin-bottom: 5px;
}
.modalstyle .balsec .coiname {
    font-size: 14px;
    color: var(--whitetext);
    font-weight: 500;
    margin-bottom: 5px;
}
.modalstyle .listsec .listrow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.modalstyle .listsec .listrow p{
    color: var(--whitetext);
    font-size: 12px;
    margin-bottom: 5px;
}
.modalstyle .listtext {
    color: var(--themecolor);
    font-size: 12px;
    margin-bottom: 5px;
    text-align: center;
}
.modalstyle .coinimgstyle{
    width: 15px;
    height: 15px;
    border-radius: 50px;
    object-fit: contain;
}
.connectwalletbtn {
    background: var(--btnliner);
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    color: var(--whitetext);
    border: 1px solid var(--btnliner);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    width: 100%;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.connectwalletbtn:hover
{
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #000 !important;
    transition: none 0s ease 0s !important;
}
.tooltip_button
{
    background-color: transparent !important;
    border:none;
    border-radius: 0px;
    color:#fff;
    padding: 0px;
    margin-left: 10px;
    margin-top: -8px;
}

.tooltip_style
{
    padding: 16px;
    font-size: 16px;
    line-height: 130%;
    border-radius: 11px;
    max-width: 320px;
    z-index: 101;
    background: rgb(35, 40, 51);
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px, rgba(14, 14, 44, 0.1) 0px 4px 12px -8px;
}






@media screen and (max-width:400px) {

    .modalstyle [class="modal-content"] {
        min-width: 288px;
        max-width: 288px;
    }
}


@media screen and (min-width:401px) and (max-width:575px) {

    .modalstyle [class="modal-content"] {
        min-width: 380px;
        max-width: 380px;
    }
}


.textendsupply

{
    text-align: end;
}
.brakval
{
    word-break: break-all;
}