.boostercard {
    background: var(--cardbg);
    border-radius: 19px;
    /* border: 1px solid var(--borderclr); */
    padding: 15px;

}

.boostercard .label {
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 5px;
}

.boostercard .boostrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.boostercard .btnsec button {
    background: var(--btnliner);
    color: var(--whitetext);
    width: 100%;
    border-radius: 0px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    border-radius: 11px;
}

.boostercard .btnsec button:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color:#000;
}

.boostercard .labelstyle {
    background: var(--themecolor);
    font-size: 10px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 2px;
    width: 25px;
    text-align: center;
}

.boostercard .toprow {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
}

.boostercard .toprow .typename {
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: right;
    color: var(--whitetext);
    margin-bottom: 5px;

}
.boostercard .toprow .imgsec{
    position: relative;
}
.boostercard .toprow .imgsec .cardimg{
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 1;
    left: 15px;
    top: 10px;
}
.boostercard .toprow .imgsec .typeimg{
    z-index: 2;
    position: relative;
    border-radius: 50px;
    width: 30px;
    height: 30px;
}
.boostercard .toprow .typesec{
    display: flex;
    align-items: end;
    flex-direction: column;
}