.modalstyle .modalheader {
    border-bottom: none;
}

.modalstyle .modalbody {
    border-bottom: none;
}

.modalstyle [class="modal-content"] {
    background: var(--cardbg);
    border-radius: 15px;

    /* box-shadow: var(--themecolor) -1px 0px 14px; */
    /* border: 1px solid var(--themecolor); */
    min-width: 410px;
    max-width: 410px;
    margin: auto;
    width: 100%;
    /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter{
    padding: 20px;
    border-top: 0px;
}
.modalstyle .btnstyle {
    background: var(--btnliner);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 10px 20px;
    border-radius: 11px;
    width: 100%;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.modalstyle .btnstyle:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #000 !important;
}
.modalstyle .modalheader .modaltitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1;
    color: var(--whitetext);

}
.modalstyle .modalheader{
    display: flex;
    justify-content: space-between;
    padding: 25px;
}
.modalstyle .modalheader .closebtn{
    background: none;
    padding: 0px;
    border: none;
    color: var(--themecolor);
    cursor: pointer;
}
.modalstyle .modalbody {
    /* display: flex;
    gap: 20px;
    justify-content: space-evenly; */
    padding:  25px;

}
.modalstyle .modalbody .label{
    font-size: 12px;
    color: var(--whitetext);
}
.modalstyle .modalbody .walletimg{
    width: 50px;
    height: 50px;
    object-fit: contain;
}
.modalstyle  .walletrow{
    width: 100px;
    height: 100px;
    padding: 10px;
}
.modalstyle  .walletrow:hover .label{
    /* background: var(--themecolor); */
    color: var(--themecolor);

}
.modalstyle  .walletrow:hover {
opacity: 0.6;
cursor: pointer;
}
.modalstyle .modalfooter .footerdesc{
    color: var(--whitetext);
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
}
.modalstyle .modalbody .innerhead{
    color: var(--themecolor);
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 24px;
    text-transform: uppercase;
}

.modalstyle .modalbody .innerheadwhite
{
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
}
.modalstyle .netbtn{
    background: var(--bgcolor);
    border: none;
    border-radius: 5px;
    color: var(--whitetext);
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 5px 15px;
    width: 100%;
    margin-bottom: 12px;
    font-size: 14px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.modalstyle
{
    padding-right: 8px !important;
}

.modalstyle .modalbody ul{
    list-style:none;
    padding-left: 0px;
}
.modalstyle .netbtn .netimg{
  width: 30px;
  height: 30px;
}
@media screen and (max-width:400px) {
    .modalstyle .modalbody .innerheadwhite
{
    font-size: 14px;
}

    .modalstyle [class="modal-content"] {
        min-width: 290px;
        max-width: 290px;
    }
   
}

@media screen and (max-width:575px)
{
    .buttonstyle_ul

{
    padding-left: 9px !important;
    padding-right: 9px !important;
}
}
.tooltip_button
{
    background-color: transparent !important;
    border:none;
    border-radius: 0px;
    color:#fff;
    padding: 0px;
    margin-left: 10px;
}

.tooltip_style
{
    padding: 16px;
    font-size: 16px;
    line-height: 130%;
    border-radius: 11px;
    max-width: 320px;
    z-index: 101;
    background: rgb(35, 40, 51);
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px, rgba(14, 14, 44, 0.1) 0px 4px 12px -8px;
}
.button_ul
{
    display: flex;
    flex-wrap: wrap;
} 
.button_ul li
{
    margin-bottom: 10px;
}
.buttonstyle_ul
{
    background-color: var(--bgcolor);
    color:#fff;
    border-radius: 11px;
    padding: 5px 13px;
    font-size: 16px;
    font-weight: 600;
}
.buttonstyle_ul:hover,.buttonstyle_ul:focus
{
    background: var(--themecolor1);
    color:#fff;
}
.sliipage_input,.sliipage_input:hover,.sliipage_input:focus
{
    background-color: var(--bgcolor);
    color:#fff;    
    border:none;
    border-radius: 11px;
    max-width: 100px;
}
.sliipage_input:focus,.form-control:focus
{
    box-shadow: 0 0 0 0.25rem rgb(72 75 81 / 12%) !important;
}
.percentage_text
{
    display: inline-block;
    color: var(--themecolor1);
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin-left: 5px;
}
.orangetext
{
    font-size:14px;
     color:#F3841E;
      
}
.sliipage_input_sm,.sliipage_input_sm:hover,.sliipage_input_sm:focus
{
    max-width: 60px;
}
.toggleswitch input {
    background: var(--themecolor);
    border: none;

}
.togglesec {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.togglesec .spanlabel {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);

}


@media screen and (min-width:401px) and (max-width:575px) {

    .modalstyle [class="modal-content"] {
        min-width: 380px;
        max-width: 380px;
    }
}

.connectwalletbtn {
    background: var(--btnliner);
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    width: 100%;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.connectwalletbtn:hover
{
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #000 !important;
    transition: none 0s ease 0s !important;
}

.cancelbtn {
    background: var(--redtext);
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    color: var(--whitetext);
    border: 1px solid var(--redtext);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    width: 100%;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.cancelbtn:hover
{
    background: var(--gradtheme) !important;
    border: 1px solid var(--gradtheme) !important;
    color: rgb(255, 255, 255) !important;
    transition: none 0s ease 0s !important;
}

.cancelbtn:disabled
{
    background: var(--redtext) !important;
    color: var(--whitetext);
    border: 1px solid var(--redtext);
    opacity: 0.5;
}