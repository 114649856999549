.connect_btn_theme {
    background: var(--btnliner);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 10px 20px;
    border-radius: 11px;
    width: 100%;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 200px;
   
    margin: 20px auto !important;
    
    /* width: 100%; */
}

.connect_btn_theme:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #000 !important;

}


.pagehead {
    font-size: 23px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--whitetext);
    margin-bottom: 5px;
}

.pagedesc {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--themecolor);
}

.comingpage
{
    min-height: calc(100vh - 400px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 20px;
}
.logocoming
{
    max-width: 150px;
    margin: 0px auto 30px auto;
}