.modalstyle .modalheader {
    border-bottom: none;
}

.modalstyle .modalbody {
    border-bottom: none;
}

.modalstyle [class="modal-content"] {
    background: var(--cardbg);
    /* box-shadow: var(--themecolor) -1px 0px 14px; */
    /* border: 1px solid var(--themecolor); */
    min-width: 370px;
    max-width: 370px;
    margin: auto;
    border-radius: 15px;
    width: 100%;
    /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter{
    padding: 20px;
    border-top: 0px;
}
.modalstyle .btnstyle {
    background: var(--btnliner);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 10px 20px;
    border-radius: 11px;
    width: 100%;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.modalstyle .btnstyle:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #000 !important;
}
.modalstyle .modalheader .modaltitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1;
    color: var(--whitetext);

}
.modalstyle .modalheader{
    display: flex;
    justify-content: space-between;
    padding: 25px;
}
.modalstyle .modalheader .closebtn{
    background: none;
    padding: 0px;
    border: none;
    color: var(--themecolor);
    cursor: pointer;
}
.modalstyle .modalbody .searchinput {
    background: var(--bgcolor);
    height: 45px;
    border: none;
    width: 100%;
    border-radius: 7px;
    padding: 10px;
    color: var(--whitetext);



}
.modalstyle .modalbody {
    padding: 0px;
}
.modalstyle .modalbody .searchinput::placeholder{
    color: var(--whitetext);
}
.modalstyle .modalbody .searchinput:focus-visible{
    outline: none;
}
.modalstyle .modalbody .tokenlists .name{
    color: var(--whitetext);
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
}
.modalstyle .modalbody .tokenlists .desc{
    color: var(--whitetext);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
}
.modalstyle .modalbody .tokenlists .timg{
   width: 25px;
   height: 25px;
   object-fit: contain;
}
.modalstyle .modalbody .tokenlists .tokenrow{
    display: flex;
    align-items: center;
    gap: 10px;
 
}
.modalstyle .modalbody .tokenlists{
    margin-top: 30px;
    padding-left: 0px;
    height: 350px;
    overflow: auto;
}
.modalstyle .modalbody .tokenlists li{
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 15px;

}
.modalstyle .modalbody .tokenlists li:hover{
    cursor: pointer;
    background: var(--blacktext);

}
.modalstyle .modalbody .topsearchsec{
    padding: 20px;
}
.modalstyle .modalbody .btngrpsec button{
    max-width: 100px;
    border: 1px solid var(--themecolor);
    background: transparent;
    border-radius: 10px !important;
    display: flex;
    padding: 6px;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 5px;
    justify-content: space-evenly;
}

.modalstyle .modalbody .btngrpsec [class="btn-group"]{
    justify-content: space-between;
    width: 100%;
    gap: 3px;
    flex-wrap: wrap;
}

.modalstyle .modalbody .btngrpsec button.activebtn{
border: 1px solid transparent;
border-radius: 10px;
display: flex;
padding: 6px;
-webkit-box-align: center;
align-items: center;
background-color: var(--themecolor);
opacity: 0.4;
}
.modalstyle .modalbody .btngrpsec button:hover{
    background-color: var(--themecolor);
}
.modalstyle .modalbody .btngrpsec .tokenimgs{
    width: 20px;
    height: 20px;
    object-fit: contain;
}
.modalstyle .modalbody .commonsec{
    padding: 0px 20px;
}
.modalstyle .modalbody .commonsec .labels{
    color: var(--themecolor);
    font-size: 1;
}
@media screen and (min-width:768px){
    .modalstyle [class="modal-content"] {
        min-width: 430px;
        max-width: 430px;
    }
    
}
@media screen and (max-width:400px) {
    .modalstyle .modalbody .innerheadwhite
{
    font-size: 14px;
}

    .modalstyle [class="modal-content"] {
        min-width: 280px;
        max-width: 280px;
    }
   
}



.connect_btn {
    background: var(--btnliner);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 10px;
    border-radius: 11px;
    width: 100%;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.connect_btn:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #000 !important;
}