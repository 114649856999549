.socialsection{
    display: flex;
    margin-top: 3%;
}
.socialsection img{
    width: 33px;
    height: 33px;
    aspect-ratio: 3/2;
    object-fit: contain;
}
.socialsection a{
  margin-left: 8px;
}
.socialsection a:nth-child(1){
    margin-left: 0px;
  }
.socialsection a:hover .mediass {
    display: none;
  }
  .socialsection a:hover .mediasshover{
      display: block;
  }
  .socialsection a .mediasshover{
      display: none;
  }
/* landing page */
.welcome{
    color: var(--whitetext);
    /* font-family: 'Kanit', sans-serif; */
    font-family: Satoshi-Bold !important;
    font-weight: 600;
}
.themetxt {
    color: var(--themecolor)!important;
}
.welcome1{
    color: var(--whitetext);
}
.marqueeimg1{
    border: 1px solid var(--themecolor);
    border-radius: 6px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    min-height: 80px;
    object-fit: contain;
}
.marqueeimg{
     /* border: 1px solid var(--themecolor);
     width: 180px;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 16px;
     min-height: 80px;
     object-fit: contain; */

     border: 1px solid var(--themecolor);
     border-radius: 6px;
     width: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 16px 25px;
     min-height: 80px;
     height: 80px;
     object-fit: contain;
     max-width: 88%;
     margin: auto;
}
.blurviolent{      
        background: #3a9a15;
        filter: blur(50px);
        height: 90px;
        opacity: .2;
        position: absolute;
        left: 20%;
        top: 10%;
        transform: rotate(178deg);
        width: 150px;
        z-index: -1;
}
.blurviolent1{      
    background: #3a9a15;
    filter: blur(50px);
    height: 90px;
    opacity: .2;
    position: absolute;
    right: 0%;
    bottom: 10%;
    transform: rotate(178deg);
    width: 120px;
    z-index: -1;
}
.blurviolentnew
{
    background: #3a9a15;
    filter: blur(38px);
    height: 80px;
    opacity: .2;
    position: absolute;
    right: 60%;
    top: 30%;
    transform: rotate(178deg);
    width: 120px;
    z-index: -1;
}
.blurviolentlast{
        background: #3a9a15;
        filter: blur(38px);
        height: 80px;
        opacity: .3;
        position: absolute;
        right: 11%;
        top: -25%;
        transform: rotate(178deg);
        width: 140px;
        z-index: -1;
}
.blurviolent2{
    background: #3a9a15;
    filter: blur(38px);
    height: 80px;
    opacity: .2;
    position: absolute;
    left: 0%;
    top: 10%;
    transform: rotate(178deg);
    width: 130px;
    z-index: -1;
}
.blur1{
        top: 0;
        position: absolute;
        max-width: 26%;
}
.blur2{
    bottom: 0;
    right: 0;
    position: absolute;
    max-width: 26%;
}
.heading{
    max-width: 70%;
    text-align: center;
    margin: auto;
}
.welcomepara{
    color: var(--lighttext);
    font-weight: 300;
    font-size: 17px;
    font-family: "Satoshi-Regular" !important;
}
.donutback{
    background: #5ab4371A;
    padding: 8px;
    border-radius: 6px;
}
.cardtext{
    font-size: 16px !important;
    text-align: left !important;
}
.tradenow{
    background:var(--btnliner);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext) ;
    border: 1px solid var(--themecolor) ;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px ;
    transition: none 0s ease 0s ;
    padding-top: 5px ;
    padding-bottom: 5px ;
    border-radius: 11px ;
    height: 40px;
    padding: 6px 30px;
}
.tradenow:hover{
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #000 !important;
    transition: none 0s ease 0s !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
.donutexp{
    width: 70px;
    height: 70px;
    object-fit: contain;
    aspect-ratio: 3/2;
}
.posrel{
    position: relative;
    z-index: 2;
    /* overflow: hidden; */
}
.donutexp1{
    position: absolute;
    right: -12%;
    max-width: 45%;
}
.donutexp2{
    position: absolute;
    right: -22%;
    max-width: 45%;
    top: 6%;
}
.donutrose{
    background: var(--themecolor);
    padding: 10px 26px;
    border-radius: 16px;
}
.donutrose img{
    margin-top: -23%;
    transform: scale(1.1);
}
.donutrose .rows{
    justify-content: space-between;
    align-items:center;
}
.blackbtn{
    background: #182322;
    color: var(--whitetext);
    padding: 6px 24px;
    border-radius: 20px;
}
.blackbtn:hover{
    background: var(--btnhover);
    color: #000;
}
.maillist{
    display: flex;
}
.maillist input{
    background: #307c14 !important;
    border: #307c14 !important;
    border-radius: 20px;
    color: var(--whitetext);
}
.maillist input::placeholder{
  color: var(--whitetext);
}
.maillist input:focus{
    box-shadow:0 0 0 0.25rem rgb(13 110 253 / 0%);
    color: var(--whitetext);
}
.partnermarquee .partnerwidth:first-child{
    margin-left: 4%;
}
.partnermarquee {
    display: flex;
    flex-direction: row;
    gap: 50px;
}
.partnerwidth{
    /* border: 1px solid var(--themecolor);
    padding: 16px;
    border-radius: 4px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center; */
}
/* .containmax{
    max-width: 90%;
} */
.coming{
    color: var(--whitetext);
}
.welcomess{
    color: var(--whitetext);
    font-size: 24px;
    text-align: left;
}
@media screen and (max-width: 1199px) {
    .containmax{
        max-width: 90%;
    }
    .donutrose{
        padding: 20px 26px;
    }
}
@media screen and (max-width: 991px) {
    .heading1{
        font-size: 29px;
    }
    .donutrose{
        padding: 20px 26px;
    }
    .marqueeimg{
        min-height: 70px;
        height: 70px;
        max-width: 95%;
    }
}
@media screen and (max-width: 767px) {
    .welcome{
        font-size: 30px !important;
    }
    .row_rev_sec
    {
        flex-direction: column-reverse;
    }
    
    .donutrose img{
        margin-top: -240px !important;
        transform: scale(1.1) !important;
    }
    .donutrose
    {
        padding-top: 150px !important;
    }
   .donutexp1,.donutexp2{
    display: none;
   }
   .heading{
    max-width: 100%;
    margin: auto;
}
.welcome{
    font-size: 30px;
}
.partnerwidth{
    width: 170px;
}
.donutrose img{
    margin-top: 5%;
}
.donutrose{
    padding: 10px 20px;
}
.marqueeimg{
    min-height: 65px;
    height: 65px;
    max-width: 90%;
}
  }


@media screen and (max-width: 575px) {
    /* .welcome{
        font-size: 23px;
        line-height: 40px !important;
    } */

    .headingeco
    {
        max-width: 80% !important;
    }
    .tradeimgpos
    {
       margin-top: 50px !important;
    }
  /* .welcome{
    font-size: 23px;
} */
.marqueeimg{
    min-height: 56px;
    height: 56px;
    max-width: 100%;
}
.coming{
   font-size: 14px;
   margin-bottom: 0px;
}
  }

  @media screen and (max-width: 340px) {
    .headingeco
    {
        max-width: 80% !important;
    }
    /* .welcome{
      font-size: 21px;
      line-height: 40px !important;
  } */
    }
    .cardstyle
    {
        background-color: #182322a8;
        border:none;
        padding:20px 20px 30px 20px;
        text-align: center;
        border-radius: 10px;
        height: 100%;
        bordeR:1px solid #34482c94;

    }
    


    @media screen and (min-width: 576px) and (max-width: 767px) {
        /* .welcome{
            font-size: 25px;
            line-height: 40px !important;
        } */
    
        .headingeco
        {
            max-width: 80% !important;
        }
        .cardstylepos1{
            margin-top: 30px !important;
        }
        .cardstylepos3{
            margin-top: -50px !important;
        }
        .tradeimgpos
        {
           transform: scale(0.8);
        }
        .donutrose img{
            margin-top: -240px !important;
            transform: scale(0.9) !important;
        }

    }

@media screen and (min-width:768px)
{
    .colwidth
    {
        width: 25% !important;
    }
}
    
    @media screen and (min-width: 768px) and (max-width: 991px) {
        .welcome{
            font-size: 30px !important;
        }
        .tradeimgpos
        {
            margin-left: 5%;
        }
        .cardstylepos1{
            margin-top: 40px !important;
        }
        .cardstylepos3{
            margin-top: 0px !important;
        }

        .cardstylepos4{
            margin-top: 35px !important;
        }

    }


    @media screen and (min-width: 992px) and (max-width: 1199px) {
        .rowminpaddtop
        {
            padding-top: 50px !important;
        }
        .tradeimgpos
        {
            margin-left: 7.5%;
        }
        .cardstylepos1{
            margin-top: 40px !important;
        }
        .cardstylepos3{
            margin-top: 20px !important;
        }

        .cardstylepos4{
            margin-top: 55px !important;
        }

    }

    @media screen and (min-width: 1200px){
        .rowminpaddtop
        {
            padding-top: 50px !important;
        }
        .heading2
        {
            font-size: 2rem !important;
        }
        .heading3
        {
            font-size: 2.5rem !important;

        }
        .welcomepink
        {
            font-size: 40px !important;
        }
        .tradeimgpos
        {
            margin-left: 7.5%;
        }
        .cardstylepos1{
            margin-top: 20px !important;
        }
        .cardstylepos2{
            margin-top: 80px !important;
        }
        .cardstylepos3{
            margin-top: -20px !important;
        }

        .cardstylepos4{
            margin-top: -90px !important;
        }

    }
    .headingeco
    {
        max-width: 60%;
        margin-left: auto;
        margin-right: auto;
        font-weight: 400 !important;
        line-height: 50px;
    }
.marqueeimg
    {
        transition: all .3s ease;
        cursor: pointer;
    }
.marqueeimg:hover
    {
        /* filter: drop-shadow(2px 4px 6px #5ab437d4); */
    transform: translateY(-8px);
    }
  
/* landing page end */

@media only screen and (min-width:1200px)
{
    .rowmin
    {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}


@media only screen and (min-width:992px) and (max-width:1199px)
{
    .rowmin
    {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .donutrose{
        width: 90%;
        margin: auto;
    }
}

/* @media only screen and (min-width: 1200px){
    .welcome {
        font-size: 40px;
    }
} */

@media screen and (min-width:120px)
{
    .donutrose
    {
    padding: 12px 26px 30px;

    }
}

.welcome{
    font-size: 3rem;
}