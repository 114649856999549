.innerpages {
    padding-top: 50px;
    padding-bottom: 70px;
    min-height: 70vh;
}

.liquiditypage .swapcardrow {
    align-items: center;
}

.liquiditypage .swapcardrow .cardstyle {
    background: transparent;
    border-radius: 13px;
    border: 2px solid var(--borderclr);
    padding: 20px;
    /* width: 450px; */
}

.liquiditypage .cardstyle .toprow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
.liquiditypage .toprow .backsec{
    display: flex;
    gap: 10px;
}
.liquiditypage .cardstyle .toprow .headings {
    color: var(--whitetext);
    font-size: 18px;
    margin-bottom: 5px;
}

.liquiditypage .cardstyle .toprow .desc {
    color: var(--themecolor);
    font-size: 14px;
    margin-bottom: 5px;
}

.liquiditypage .cardstyle .toprow .iconsec {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

}

.liquiditypage .cardstyle .toprow .iconsec svg {
    fill: var(--whitetext);
    font-size: 24px;
    cursor: pointer;
}

.liquiditypage .cardstyle .toprow .iconsec svg:hover {
    fill: var(--themecolor);
}

.liquiditypage .cardstyle .inputsec {
    background: var(--borderclr);
    padding: 12px;
    border-radius: 12px;
}

.liquiditypage .cardstyle .inputsec .inputlabel {
    color: var(--whitetext);
    font-size: 14px;
}

.liquiditypage .cardstyle .inputsec input {
    background: none;
    border: none;
    color: var(--whitetext);
    width: 100%;
}
.liquiditypage .cardstyle .inputsec input::placeholder{
    color: var(--whitetext);
}
.liquiditypage .cardstyle .inputsec input:focus-visible {
   outline: none;
}

.liquiditypage .cardstyle .inputsec input:focus {
    box-shadow: none;
    border: none !important;
    justify-content: center;
}

.liquiditypage .cardstyle .swaparrow {
    background: var(--themecolor);
    border-radius: 50px;
    height: 32px;
    width: 32px;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

}
.liquiditypage.findpage .cardstyle .swaparrow {
    cursor:inherit;
}
.liquiditypage .cardstyle .swaparrow svg {
    font-size: 14px;
}

.liquiditypage .cardstyle .arrowrow {
    justify-content: center;
    margin-bottom: 20px;
}

.liquiditypage .connect_btn {
    background: var(--btnliner);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 10px 20px;
    border-radius: 11px;
    width: 100%;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

}

.liquiditypage .connect_btn:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #000 !important;
    font-weight: 700;

}
.liquiditypage .currencybtn{
    background: var(--cardbg);
    padding: 4px;
    color: var(--whitetext);
    font-size: 14px;
    border: none;
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.liquiditypage .currencybtn:hover{
    background: var(--themecolor);
}
.liquiditypage .currencybtn .coinimg{
    width: 20px;
    height: 20px;
    object-fit: contain;
}
.liquiditypage .inputsec .rightbtnsec{
    display: flex;
    justify-content: end;
}
.liquiditypage .inputsec .inputsecrow{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.liquiditypage .inputsec .inputsecrow .rightmaxbtn{
    display: flex;
    align-items: center;
    gap: 10px;
}
.liquiditypage .inputsec .balancerow{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.liquiditypage .innercontentsec {

    padding-top: 20px;
    padding-bottom: 20px;
}
.liquiditypage .innercontentsec .labelname{
    color: var(--whitetext);
    font-size: 16px;
    text-align: center;
}
.liquiditypage .innercontentsec .findbtn{
    text-align: center;
    background: transparent;
    border: 1px solid var(--themecolor);
    border-radius: 12px;
    padding: 10px;
    text-decoration: none;
    color: var(--whitetext);
    font-weight: 600;
}
.liquiditypage .innercontentsec .findbtn:hover{
    background: var(--themecolor);
    opacity: 0.65;
}
.liquiditypage .innercontentsec .findbtnsec{
    text-align: center;
}
.liquiditypage .cardstyle .toprow .backbtn{
    cursor: pointer;
}
.liquiditypage.findpage .currencybtn{
    background: transparent !important;
    width: 100%;
    justify-content: space-between;

}
.liquiditypage.findpage .currencybtn .imgbtn{
    display: flex;
    align-items: center;
    gap: 10px;
}
.liquiditypage.findpage .findlabelsec{
    border: 1px solid var(--themecolor);
    border-radius: 13px;
    padding: 45px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.liquiditypage.findpage .findlabelsec .findlabelcontent{
    color: var(--whitetext);
    margin-bottom: 0px;

}
.liquiditypage .extracontentsec .cardstylesec{
    background: var(--cardbg);
    border-radius: 15px;
    border: none;
    padding: 15px;
    width: 185px;
}
.liquiditypage .extracontentsec .cardstylesec .head{
    color: var(--whitetext);
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 5px;
}
.liquiditypage .extracontentsec .cardstylesec .desc{
    color: var(--desctext);
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0px;
}
.liquiditypage .extracontentsec{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    flex-direction: row;
}
.liquiditypage.findpage .extrasecs .extracard ,.liquiditypage.addpage .extrasecs .extracard{
    background: var(--cardbg);
    padding: 20px;
    border-radius: 13px;
    margin-bottom: 20px;
}
.liquiditypage.findpage .extrasecs .mainhead, .liquiditypage.addpage .extrasecs .mainhead{
    color: var(--whitetext);
    font-size: 24px;
    font-weight: 700;
}
.liquiditypage.findpage .extrasecs .extracard .desc, .liquiditypage.addpage .extrasecs .extracard .desc{
    color: var(--whitetext);
    margin-bottom: 0px;
    font-size: 14px;
}
@media screen and (min-width:1351px) {
    .liquiditypage .extracontentsec .cardstylesec{
width: 185px;
    }
    
}


@media screen and (max-width:575px)
{
    .liquiditypage .extracontentsec .cardstylesec
    {
        width: 100%;
    }
}

@media screen and (min-width:768px) and (max-width:991px)
{
    .liquiditypage .extracontentsec .cardstylesec
    {
        width: 200px;
    }
}

@media screen and (min-width:992px) and (max-width:1199px)
{
    .liquiditypage .extracontentsec .cardstylesec
    {
        width: 210px;
    }
}

@media screen and (min-width:1200px) and (max-width:1399px) 
{
    .liquiditypage .extracontentsec .cardstylesec
    {
        width:190px;
    }
}
@media screen and (min-width:1400px) 
{
    .liquiditypage .extracontentsec .cardstylesec
    {
        width:225px;
    }
}

/* NEW CSS */
.liquiditypage .desc_cardsec{
    border: 1px solid var(--themecolor);
    padding: 15px;
    background: var(--bgcolor);
    border-radius: 13px;
}
.liquiditypage .desc_cardsec .descpara{
   color: var(--desctext);
   font-size: 14px;
   margin-bottom: 0px;
}
.liquiditypage .valuecardsec {
    background: var(--cardbg);
    padding: 20px;
    border-radius: 13px;
    margin-bottom: 20px;

}
.liquiditypage .valuecardsec .descpara{
    color: var(--themecolor);
    font-size: 14px;
    text-align: center;
}
.liquiditypage .valuecardsec .label{
    color: var(--whitetext);
    font-size: 14px;
    text-align: center;
    margin-bottom: 5px;
}
.liquiditypage .valuecardsec .labels{
    color: var(--themecolor);
    font-size: 14px;
    text-align: center;
}
.liquiditypage .valuecardsec  .secrow{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.liquiditypage .balancecardsec {
    background: var(--cardbg);
    padding: 20px;
    border-radius: 13px;
    margin-bottom: 20px;
}
.liquiditypage .balancecardsec .head{
    color: var(--themecolor);
    font-size: 16px;
    font-weight: 500;
}
.liquiditypage .balancecardsec ul{
   list-style: none;
   padding-left: 0px;
}
.liquiditypage .balancecardsec ul li{
   display: flex;
   align-items: center;
   justify-content: space-between;
   flex-direction: row;
   margin-bottom: 10px;
 }
 .liquiditypage .balancecardsec ul li p{
    margin-bottom: 0px;
    color: var(--desctext);
    font-size: 14px;
 }
 .liquiditypage .balancecardsec .coinimg{
    width: 15px;
    height: 15px;
    object-fit: contain;
 }

 .liquiditypage .balancecardsec .tooltip_button
{
    background-color: transparent !important;
    border:none;
    border-radius: 0px;
    color:#fff;
    padding: 0px;
    margin-left: 5px;
    margin-top: -5px;
}

.liquiditypage .balancecardsec .tooltip_style
{
    padding: 16px;
    font-size: 16px;
    line-height: 130%;
    border-radius: 11px;
    max-width: 320px;
    z-index: 101;
    background: rgb(35, 40, 51);
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px, rgba(14, 14, 44, 0.1) 0px 4px 12px -8px;
}

.liquiditypage .desccardsec {
    background: var(--cardbg);
    padding: 20px;
    border-radius: 13px;
    margin-bottom: 20px;
}
.liquiditypage  .desccardsec .head{
    color: var(--themecolor);
    font-size: 14px;
    text-align: center;
    margin-bottom: 0px;
}
.liquiditypage  .desccardsec .coinimg{
    width: 15px;
    height: 15px;
}


/* 22 FEB CSS */
.liquiditypage .poolaccordion .coinimg {
    width: 20px;
    height: 20px;
    object-fit: contain;
    /* filter: invert(1); */

}

.liquiditypage .poolaccordion_ul .labelhead {
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 600;
}

.liquiditypage .poolaccordion [class="accordion-header"] button {
    background: var(--bgcolor);
    border: none;
    box-shadow: none;
}

.liquiditypage .poolaccordion [class="accordion-item"] {
    border: none;
    box-shadow: none;

}

.liquiditypage .poolaccordion [class="accordion-item"]:hover {
    box-shadow: none;
}

.liquiditypage .poolaccordion [class="accordion-header"] .labelhead {
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.liquiditypage .poolaccordion [class="accordion-header"] .balance {
    color: var(--themecolor);
    font-size: 14px;
    font-weight: 500;
}

.liquiditypage .poolaccordion .poolaccordion_ul {
    padding-left: 0px;
    list-style: none;
}

.liquiditypage .poolaccordion .poolaccordion_ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.liquiditypage .poolaccordion .poolaccordion_ul li .labelname {
    color: var(--desctext);
    font-size: 14px;
    margin-bottom: 5px;
}

.liquiditypage .poolaccordion [class="accordion-body"] {
    background: var(--bgcolor);
}

.liquiditypage .poolaccordion .walletbtn {
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 500;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 10px;
    border-radius: 11px;
    width: 100%;
}

.liquiditypage .poolaccordion .walletbtn:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);

}

.liquiditypage .poolaccordion [class="accordion"] {
    margin-bottom: 20px;
}

.liquiditypage .poolaccordion .transbtn {
    background: transparent;
    white-space: nowrap;
    font-weight: 500;
    color: var(--themecolor);
    border: none;
    box-shadow: none;
    padding: 10px;

    width: 100%;
}

.liquiditypage .poolaccordion .transbtn:hover {

    color: var(--whitetext);

}

.liquiditypage .poolaccordion button:not(.collapsed)::after {
    filter: invert(1);
}
.liquiditypage .poolaccordion button:focus{
    box-shadow: none;
}
.liquiditypage.removepage .pricesec .pricecard{
    background: var(--bgcolor);
    padding: 15px;
    border-radius: 15px;
    margin-bottom: 20px;

}
.liquiditypage.removepage .pricesec .pricecard ul{
    padding-left: 0px;
    list-style: none;
}
.liquiditypage.removepage .pricesec .pricecard li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

}
.liquiditypage.removepage .pricesec .pricecard li p{
    color: var(--themecolor);
    font-size: 14px;
    margin-bottom: 0px;
}
.liquiditypage.removepage .btnsec{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}


/* 22 FEB CSS */
.liquiditypage .poolaccordion .coinimg {
    width: 20px;
    height: 20px;
    object-fit: contain;
    /* filter: invert(1); */

}

.liquiditypage .poolaccordion_ul .labelhead {
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 600;
}

.liquiditypage .poolaccordion [class="accordion-header"] button {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    background: var(--cardbg);
    border: none;
    box-shadow: none;
}

.liquiditypage .poolaccordion [class="accordion-item"] {
    border: none;
    box-shadow: none;
    background-color: transparent !important;
    border-radius: 0px !important;


}

.liquiditypage .poolaccordion [class="accordion-item"]:hover {
    box-shadow: none;
}

.liquiditypage .poolaccordion [class="accordion-header"] .labelhead {
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.liquiditypage .poolaccordion [class="accordion-header"] .balance {
    color: var(--themecolor);
    font-size: 14px;
    font-weight: 500;
}

.liquiditypage .poolaccordion .poolaccordion_ul {
    padding-left: 0px;
    list-style: none;
}

.liquiditypage .poolaccordion .poolaccordion_ul li {
    display: grid;
    align-items: flex-start;
    justify-content: space-between;
    grid-template-columns: 49% 49%;
    grid-column-gap: 1%;
}

.liquiditypage .poolaccordion .poolaccordion_ul li .labelname {
    color: var(--desctext);
    font-size: 14px;
    margin-bottom: 5px;
    word-break: break-all;
    display: flex;
    align-items: center;
}

.liquiditypage .poolaccordion [class="accordion-body"] {
    background: var(--cardbg);
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.liquiditypage.removepage .pricesec .pricecard .coinimg {
    width: 15px;
    height: 15px;
    object-fit: contain;
}

.liquiditypage .poolaccordion .walletbtn {
    background: var(--btnliner);
    white-space: nowrap;
    font-weight: 500;
    color: var(--whitetext);
    font-weight: 600;
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 10px;
    border-radius: 11px;
    width: 100%;
}

.liquiditypage .poolaccordion .walletbtn:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #000 !important;

}

.liquiditypage .poolaccordion [class="accordion"] {
    margin-bottom: 20px;
    border-radius: 0px !important;
}

.liquiditypage .poolaccordion .transbtn {
    background: transparent;
    white-space: nowrap;
    font-weight: 500;
    color: var(--themecolor);
    border: none;
    box-shadow: none;
    padding: 10px;

    width: 100%;
}

.liquiditypage .poolaccordion .transbtn:hover {

    color: var(--whitetext);

}

.liquiditypage .poolaccordion button:not(.collapsed)::after {
    filter: invert(1);
}
.liquiditypage .poolaccordion button:focus{
    box-shadow: none;
}
.liquiditypage.removepage .pricesec .pricecard{
    background: var(--bgcolor);
    padding: 15px;
    border-radius: 15px;
    margin-bottom: 20px;

}
.liquiditypage.removepage .pricesec .pricecard ul{
    padding-left: 0px;
    list-style: none;
}
.liquiditypage.removepage .pricesec .pricecard li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

}
.liquiditypage.removepage .pricesec .pricecard li p{
    color: var(--themecolor);
    font-size: 14px;
    margin-bottom: 0px;
}
.liquiditypage.removepage .btnsec{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}


/* 23 FEB CSS */
.liquiditypage.removepage .tabrow .labelname {
    color: var(--themecolor);
    font-size: 14px;
    margin-bottom: 0px;
}

.liquiditypage.removepage .tabrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.liquiditypage.removepage .tabrow .tabbtn {
    background: var(--themecolor);
    color: var(--whitetext);
    padding: 5px 10px;
    font-size: 14px;
}

.liquiditypage.removepage .tabrow .tabbtn:hover {
    background: var(--btnhover);
}

.liquiditypage.removepage .rangesec {
    border: 1px solid var(--lighttext);
    padding: 10px;
    border-radius: 13px;
    margin-bottom: 10px;
}

.liquiditypage.removepage .rangesec .rangeshow {
    color: var(--themecolor);
    font-size: 20px;
    font-weight: 600;
}

.liquiditypage.removepage .rangesec .btngrpsec .btnstyle {
    background: var(--themecolor);
    color: var(--whitetext);
    font-size: 12px;
    width: 100px;
    padding: 5px;
}

.liquiditypage.removepage .rangesec .btngrpsec .btnstyle:hover {
    background: var(--btnhover);
}

.liquiditypage.removepage .rangesec .btngrpsec {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}



  .liquiditypage.removepage .rangesec .rangeinput[type="range"] {
    -webkit-appearance: none;
    appearance: none; 
    width: 100%;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border-radius: 16px;
    height: 20px;
    margin-bottom: 20px;
  }


  .liquiditypage.removepage .rangesec .rangeinput[type="range"]::-webkit-slider-runnable-track {
    height: 15px;
    background: var(--bgcolor);
    border-radius: 16px;
  }


  .liquiditypage.removepage .rangesec .rangeinput[type="range"]::-moz-range-track {
    height: 15px;
    background: var(--bgcolor);
    border-radius: 16px;
  }


  .liquiditypage.removepage .rangesec .rangeinput[type="range"]::-webkit-slider-thumb {

    -webkit-appearance: none;
    appearance: none; 
    height: 20px;
    width: 20px;
    background-color: var(--whitetext);
    border-radius: 50%;
    border: 1px solid var(--whitetext);
    box-shadow: -407px 0 0 400px var(--themecolor);
    margin-top: -3px;
  }


  /* Thumb: Firefox */
  .liquiditypage.removepage .rangesec .rangeinput[type="range"]::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid var(--themecolor);
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px var(--themecolor);
  }



  /* 23 FEB CSS */
  .liquiditypage.removepage .tabrow .labelname {
    color: var(--themecolor);
    font-size: 14px;
    margin-bottom: 0px;
}

.liquiditypage.removepage .tabrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.liquiditypage.removepage .tabrow .tabbtn {
    background: var(--themecolor);
    color: var(--whitetext);
    padding: 5px 10px;
    font-size: 14px;
}

.liquiditypage.removepage .tabrow .tabbtn:hover {
    background: var(--btnhover);
    color:var(--blacktext);
}

.liquiditypage.removepage .rangesec {
    /* border: 1px solid var(--lighttext); */
    border:none;
    padding: 20px;
    border-radius: 13px;
    margin-bottom: 10px;
    background: var(--cardbg);
}

.liquiditypage.removepage .rangesec .rangeshow {
    color: var(--themecolor);
    font-size: 20px;
    font-weight: 600;
}

.liquiditypage.removepage .rangesec .btngrpsec .btnstyle {
    background: var(--themecolor);
    color: var(--whitetext);
    font-size: 12px;
    width: 100px;
    padding: 5px;
}

.liquiditypage.removepage .rangesec .btngrpsec .btnstyle:hover {
    background: var(--btnhover);
}

.liquiditypage.removepage .rangesec .btngrpsec {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}



  .liquiditypage.removepage .rangesec .rangeinput[type="range"] {
    -webkit-appearance: none;
    appearance: none; 
    width: 100%;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border-radius: 16px;
    height: 20px;
    margin-bottom: 20px;
  }


  .liquiditypage.removepage .rangesec .rangeinput[type="range"]::-webkit-slider-runnable-track {
    height: 15px;
    background: var(--bgcolor);
    border-radius: 16px;
  }


  .liquiditypage.removepage .rangesec .rangeinput[type="range"]::-moz-range-track {
    height: 15px;
    background: var(--bgcolor);
    border-radius: 16px;
  }


  .liquiditypage.removepage .rangesec .rangeinput[type="range"]::-webkit-slider-thumb {

    -webkit-appearance: none;
    appearance: none; 
    height: 20px;
    width: 20px;
    background-color: var(--whitetext);
    border-radius: 50%;
    border: 1px solid var(--whitetext);
    box-shadow: -407px 0 0 400px var(--themecolor);
    margin-top: -3px;
  }


  /* Thumb: Firefox */
  .liquiditypage.removepage .rangesec .rangeinput[type="range"]::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid var(--themecolor);
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px var(--themecolor);
  }

  .liquiditypage.removepage .rangesec .btngrpsec.btngrpsecnowrap .btnstyle
  {
  background:var(--bgcolor) !important;
  }
  .liquiditypage.removepage .rangesec .btngrpsec.btngrpsecnowrap .btnstyle:hover
  {
  background:var(--themecolor) !important;
  }
  .liquiditypage.removepage .rangesec .btngrpsec.btngrpsecnowrap
{
    flex-wrap: nowrap;
}

.linktheme
  {
    color: var(--themecolor);
  }
  .linktheme:hover
  {
    color: #fff;
  }

  .btnrightpostion
{
    max-width: 100px;
    text-align: center;
    justify-content: center;
    margin-left: auto;
    background-color: var(--cardbg) !important;
}
.btnrightpostion:hover
{
    background-color: var(--themelite) !important;
    background: var(--themelite) !important;
}