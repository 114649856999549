.footersec {
    background: var(--gradtheme);
    padding: 10px;
    padding-top: 30px;
}

.footersec .customcontainer {
    max-width: 1200px !important;
    /* width: 90%; */
}

.footersec .centersec .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: justify;
    color: var(--whitetext);
    margin-bottom: 25px;

}

.footersec .leftsec .logoimg {
    width: 250px;

}

.footersec .centersec .pageroute {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: justify;
    color: var(--desctext);
    text-decoration: none;
    margin-bottom: 10px;

}

.footersec .centersec .pageroute:hover {
    color: var(--themecolor);

}

.footersec .centersec .leftsec {
    text-align: center;
}

.footersec .centersec .routerow ul {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 45px;
}

.footersec .centersec .routerow li {
    margin-bottom: 10px;
}

.footersec .rightsec {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.footersec .rightsec .labelsec {
    background: var(--bgcolor);
    padding: 5px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    max-width: 120px;
    min-width: 120px;
    height: 44px;
}

.footersec .rightsec .labelsec .minilogo {
    width: 36px;
    object-fit: contain;
    aspect-ratio: 6 / 5;
}

.footersec .rightsec .labelsec .labeltxt {
    color: var(--whitetext);
    font-size: 14px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    margin-bottom: 0px;
}

.footersec .socialsec li {
    background: var(--bgcolor);
    border-radius: 50px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
}


.footersec .socialsec {
    list-style: none;
}

.footersec .socialsec ul {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
    padding-left: 0px;
}

.footersec .socialsec li:hover {
    background: var(--btnliner);
}

.footersec .socialsec li svg {
    fill: var(--labelbg);
}
.footersec .socialsec li:hover svg {
    fill: var(--bgcolor);
}

.footersec .copytext {
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--desctext);
    text-decoration: none;
    margin-top: 15px;
    margin-bottom: 15px;
}

.hrline {
    border-top: 1px solid  var(--desctext) ;
    margin-bottom: 35px;
    opacity: 1;

}

.footersec .btmmenu ul {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    padding-left: 0px;

}
.footersec .btmmenu{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.footersec .btmmenu ul .pageroute {
    font-size: 16px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: justified;
    color: var(--whitetext);
    text-decoration: none;

}
.footersec .btmmenu ul .pageroute:hover {

    color: var(--themecolor);


}
.footersec  .socialsec a{
    margin-left: 5px;
    }
  .footersec  .socialsec  img{
    width:34px;
    height:34px;
    object-fit: contain;
    aspect-ratio: 3/2;
  }
.dflex{
    display: flex;
}
.footersec  .socialsec a:hover .mediass {
  display: none;
}
.footersec  .socialsec a:hover .mediasshover{
    display: block;
}
.footersec  .socialsec a .mediasshover{
    display: none;
}

@media only screen and (min-width:576px) and (max-width:767px)
{
    .footersec .flexFooter ul
  {
    padding-left:30px !important;
  }
  .footersec .flexFooter
  {
    display: grid;
    grid-template-columns: 50% 40%;
  }
  .footersec .topsecrow{
    width: 100%;
}
}


@media screen and (max-width:576px) {
    .footersec {
        padding-bottom: 80px;
    }
}
@media screen and (max-width:767px) {
    .footersec .btmmenu{
        flex-direction: column;
    }
    .footersec .rightsec{
        width: 100%;
    }
    .footersec .rightsec .labelsec{
        width: 100%;
        /* min-width: unset;
        max-width: unset; */

    }
    .footersec {
        padding-top: 60px;
    }
}
@media screen and (min-width:768px) {
.footersec .centersec .routerow ul {
    margin-bottom: 40px;
}
}
@media screen and (min-width:992px)
{
    .flexFooter .routerow
{
padding-left: 30px;
padding-right: 30px;
}
    .flexFooter
    {
        padding-left: 60px;
        padding-right: 70px;
    }
}

@media screen and (max-width: 780px)
{
    .flexFooter {
        flex-direction: column;
    }
    .flexFooter .routerow
{
padding-left: 0px;
width: 100%;
}
}
@media screen and (max-width:991px) {
    .footersec .leftsec {
        text-align: left;
    }

    .footersec .socialsec ul {
        justify-content: left;
    }

    .footersec .centersec .routerow li,
    .footersec .centersec .routerow .title {
        text-align: left;
    }

    .footersec .centersec .routerow ul {
        padding-left: 0px;
    }

    .footersec .rightsec {
        align-items: start;
        justify-content: center;
    }
   
   .footersec .copysec {
    margin-bottom: 25px;

    }
}
@media screen and (min-width:768px) and (max-width:991px) {
.hrline {
    margin-bottom: 50px;


}
   
.flexFooter .routerow
{
padding-left: 30px;
}
.footersec .flexFooter
{
    flex-direction: row !important;
  /* display: grid !important;
  grid-template-columns: 60% 30%; */
}
}

@media screen and (min-width:768px) {
.footersec .copytext {
    margin-bottom: 30px;
}

}

.flexFooter {
    display: flex;
    justify-content: space-between;
}
/* .flexFooter .routerow
{
padding-left: 30px;
padding-right: 30px;
} */


