.innerpages {
    padding-top: 50px;
    padding-bottom: 70px;
    min-height: 70vh;
}
/* .innerpages [class="container"]{
    max-width: 1200px;
} */
.innerpages .headrow .pagehead {
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 5px;
}

.innerpages .headrow .pagedesc {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--themecolor);
}












.innerpages .balancecard {
    background: #0000;
    border: 1px solid var(--borderclr);
    padding: 15px;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */

}

.innerpages .balancecard .labelname {
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--lighttext);
    margin-bottom: 5px;
}

.innerpages .balancecard .labelvalue {
    font-size: 14px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 0px;
}

.innerpages .balancecard .balanceimg {
    width: 25px;

}
.balanceimg
{
    width: 25px;

}

.leftimgsec {
    position: relative;
    max-width: 50px;
}
.leftimgsec .fromimg {
    position: relative;
    z-index: 2;
    width: 40px;
    height: 40px;
    object-fit: contain;

}
.leftimgsec .toimg {
    position: absolute;
    z-index: 0;
    right: -25px;
    z-index: 1;
    top: 0px;
    width: 40px;
    height: 40px;
    object-fit: contain;
}
.coinlogoname
{
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 5px;
}
.themelink
{
    color:var(--themecolor);
    text-decoration: none;
}
.themelink:hover{
    color:var(--whitetext);
}
.textwhitedesc
{
    color:var(--whitetext);
    font-size: 14px;
}
.textwhitedesclg
{
    color:var(--whitetext);
    font-size: 20px;
    font-weight: 700;
}
.textgreydesc
{
    color:var(--lighttext);
    font-size: 14px;
}
.themeapr
{
    color:var(--themecolor);
    font-size: 14px;
}

.tooltip_style
{
    padding: 16px;
    font-size: 16px;
    line-height: 130%;
    border-radius: 11px;
    max-width: 320px;
    z-index: 101;
    background: rgb(35, 40, 51);
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px, rgba(14, 14, 44, 0.1) 0px 4px 12px -8px;
}
.tooltip_button
{
    background-color: transparent !important;
    border:none;
    border-radius: 0px;
    color:#fff;
    padding: 0px;
    margin-left: 10px;
}
.carddetail
{
    display: grid;
    width: 100%;
    grid-template-columns: 16% 12% 12% 12% 12% 12% 12% 12%;
    justify-content: space-between;

}
.carddetailval .labelname
{
text-transform: uppercase;
}
.nopoolimg
{
    max-width: 200px;
}


.connect_btn {
    background: var(--btnliner);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 10px 20px;
    border-radius: 11px;
    /* width: 100%; */
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

}

.connect_btn:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #000 !important;

}


.connect_btn_disable {
    background: var(--cardbg);
    white-space: nowrap;
    font-weight: 500;
    color: var(--whitetext);
    border: 1px solid var(--cardbg);
    transition: none 0s ease 0s;
    padding: 5px 20px;
    border-radius: 0px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
}

.connect_btn_disable:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);

}


@media screen and (max-width:575px)
{
    .felxdiv
    {
        flex-direction: column !important;
        align-items: flex-start !important;
    }
    .carddetail
    {
        grid-template-columns: 100%;
    }
    .carddetail .carddetailval
    {
        margin-bottom: 10px;
    }
}

@media screen and (min-width:576px) and (max-width:767px)
{
    .felxdiv
    {
        display: grid !important;
        grid-template-columns: 50% 50%;
    }
    .carddetail
    {
        grid-template-columns: 33% 33% 33%;

    }
    .carddetail .carddetailval
    {
        margin-bottom: 10px;
    }
}

@media screen and (min-width:768px) and (max-width:991px)

{
    .carddetail
    {
        grid-template-columns: 33% 33% 33%;

    }
    .carddetail .carddetailval
    {
        margin-bottom: 10px;
    }
}