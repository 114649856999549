.innerpages {
    padding-top: 50px;
    padding-bottom: 70px;
    min-height: 70vh;
}
/* .innerpages [class="container"]{
    max-width: 1200px;
} */
.innerpages .headrow .pagehead {
    font-size: 23px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 5px;
}

.innerpages .headrow .pagedesc {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--themecolor);
}
.innerpages .filterrow .leftfiltersec{
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
        

    
}
.innerpages .filterrow{
    align-items: center;
}
.innerpages .filterrow .leftfiltersec .liststyle{
    display: flex;
    gap: 15px;
    align-items: center;
}
.innerpages .filterrow .leftfiltersec .liststyle svg{
    cursor: pointer;

}
.innerpages .filterrow .leftfiltersec .liststyle svg:hover{
    fill: var(--themecolor);

}
.innerpages .filterrow .leftfiltersec .togglesec {
    display: flex;
    align-items: center;
    gap: 10px;
}
.innerpages .filterrow .leftfiltersec .togglesec .spanlabel{
    color: var(--whitetext);
}
.innerpages  .filterrow  .rightfiltersec{
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;

}
.innerpages .filterrow .leftfiltersec .tabsec{
    background: var(--cardbg);
    display: flex;
    border-radius: 12px;
}
.innerpages .filterrow .leftfiltersec .tabsec p{
    color: var(--whitetext);
    font-size: 15px;
    font-weight: 700;
    padding: 5px 20px;
    margin-bottom: 0px;
    cursor: pointer;
}
.innerpages .filterrow .leftfiltersec .tabsec p.active{
    background: var(--whitetext);
    color: var(--blacktext);
    border-radius: 12px;
    
}
.innerpages .filterrow .leftfiltersec .tabsec p:hover{
    color: var(--themecolor);
    opacity: 0.65;
}
.innerpages .dropdownstyle button {
    background: var(--cardbg);
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.innerpages .dropdownstyle button:active {
    background: var(--bgcolor);
    border: none;
}

.innerpages .dropdownstyle .dropdownmenustyle {
    background: var(--cardbg);
    border: none;
    width: 100%;
    color: var(--whitetext);
}

.innerpages .dropdownstyle .dropdownmenustyle a {
    color: var(--whitetext);
}

.innerpages .dropdownstyle .dropdownmenustyle a:hover {
    color: var(--themecolor);
    background: none;
}
.innerpages .rightfiltersec .labelname{
    color: var(--themecolor);
    font-size: 14px;
}
.innerpages .rightfiltersec .inputstyle{
    background:var(--cardbg);
    box-shadow: none;
    border: none;
    color: var(--whitetext);
}
.innerpages .rightfiltersec .inputstyle::placeholder{
    color: var(--whitetext);
}
.innerpages .rightfiltersec .inputstyle:focus{
    box-shadow: none;
}
.innerpages .farmcontentsec .emptylabels{
    font-size: 18px;
    color: var(--themecolor);
    text-align: center;
}
.innerpages .poolscardsec{
    display: flex;
    align-items: center;
    justify-content: center;
}
.innerpages  .finshedlabel{
    color: var(--whitetext);
    text-align: center;
    margin-top: 30px;
}
.innerpages .farmtoprow .leftsec .pagehead{
    font-size: 23px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 5px;

}
.innerpages .farmtoprow .leftsec .pagedesc{
    font-size: 16px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--desctext);
    margin-bottom: 5px;

}
.innerpages .farmtoprow .rightsec .labelname {
    color: var(--whitetext);
    font-size: 15px;
    text-align: right;
    margin-bottom: 10px;
}
.innerpages .farmtoprow .rightsec .labellink {
    color: var(--themecolor);
    font-size: 14px;
    text-align: right;
    margin-bottom: 20px;
    text-decoration: none;
}
.innerpages .farmtoprow .rightsec .labellink:hover{
    color: var(--btnhover);
}
.innerpages .farmtoprow .rightsec .linksec{
    text-align: end;
    margin-bottom: 20px;
}
.innerpages .farmtoprow .btnsec .btnstyle{
    background: var(--btnliner);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 40px;
}
.innerpages .farmtoprow .btnsec .btnstyle:hover{
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #000 !important;
    transition: none 0s ease 0s !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
.innerpages.poolpage .topcardsec{
    background: var(--cardbg);
    padding: 10px;
    border-radius: 12px;
    margin-bottom: 20px;
}
.innerpages.poolpage .topcardsec .heading{
    color: var(--whitetext);
    font-size: 18px;
    font-weight: 700;
}
.innerpages.poolpage .topcardsec .desc{
    color: var(--desctext);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
}
.innerpages.poolpage .extracardsec .cardstyle{
    padding: 10px;
    border-radius: 15px;
    border:1px solid var(--desctext);
}
.innerpages.poolpage .extracardsec .cardstyle .label{
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}
.innerpages.poolpage .extracardsec .cardstyle .linkstyle{
    color: var(--desctext);
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
}
.innerpages.poolpage .extracardsec .cardstyle .linkstyle:hover{
    color: var(--themecolor);
}
.innerpages.poolpage .extracardsec{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
@media screen and (max-width:575px) {
    .innerpages .filterrow .leftfiltersec{
        justify-content: center;

    }
}
@media screen and (max-width:991px) {
    .innerpages .farmtoprow .rightsec .linksec{
        text-align: left;
        margin-bottom: 20px;
    }
    .innerpages .farmtoprow .rightsec .labelname {
        text-align: left;
    }
}
@media screen and (max-width:500px)
{
    .innerpages .farmtoprow .btnsec .btnstyle
    {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 13px;
    }
}

.emptylabels{
    font-size: 18px;
    color: var(--themecolor);
    text-align: center;
}